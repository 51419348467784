<template>
  <div class="pay">
    <div class="pay_concent">
      <div class="pay_success">
        <div class="payitem" v-if="list.pay_status == 1">
          <img
            src="https://smart-nm.oss-cn-qingdao.aliyuncs.com/ydp/success.png?x-oss-process=image/resize,p_50"
            class="imgsuccess"
          />
          <div class="successsfont">支付成功</div>
        </div>
        <div class="payitem" v-if="list.pay_status == 0">
          <img
            src="https://smart-nm.oss-cn-qingdao.aliyuncs.com/ydp/error.png?x-oss-process=image/resize,p_50"
            class="imgsuccess"
          />
          <div class="successsfont">支付未成功</div>
        </div>
      </div>
      <div class="pay_concent2">
        <div class="pay_concent3">
          <div class="pay_concent4">
            <div>收 款 方</div>
            <div class="pay_concent5">{{ list.merchant || "暂无" }}</div>
          </div>
          <div class="pay_concent4">
            <div>订 单 号</div>
            <div class="pay_concent5">
              {{ this.order_no || "暂无" }}
            </div>
          </div>
           <div class="pay_concent4">
            <div>订单金额</div>
            <div class="pay_concent6">
              <span>￥</span>{{ list.order_amount || "暂无" }}
            </div>
          </div>
          <div class="pay_concent4">
            <div>支付金额</div>
            <div class="pay_concent6">
              <span>￥</span>{{ list.pay_amount || "暂无" }}
            </div>
          </div>

         
        </div>
      </div>
    </div>
    <div class="concent3" v-if="is_mini_jump == 1">
      <div style="width: 100%; display: flex; justify-content: center">
        <img
          @click="zhifu_success"
          class="wx"
          style="width: 100%; margin-top: 70px; height: 120px"
          src="./../assets/img/login.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
var Interval;
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
var typeBower = null;
import { weixinJSconfig, getpaysuccesssTradeOut } from "@/api/pay";
export default {
  name: "pay_success",
  data() {
    return {
      list: {},
      url: null,
      ad_urlisshow: null,
      timeshow: 0,
      order_no: "",
      is_mini_jump: 0,
      username: "",
    };
  },
  created() {
    let order_no = this.$route.query.out_trade_no;
    if (!order_no) {
      order_no = window.localStorage.getItem("order_no");
    }
    this.order_no = order_no;
    this.url = location.href;
    //查询订单操作
    if (order_no != "") {
      //当前循环的次数
      Interval = setInterval(() => {
        (() => {
          this.timeshow++;
          if (this.timeshow * 1 < 5) {
            getpaysuccesssTradeOut({ order_no: order_no }).then((res) => {
              if (res.resultCode == 1) {
                if (res.resultInfo.pay_status == 1) {
                  clearInterval(Interval);
                }

                this.list = res.resultInfo;
                this.is_mini_jump = this.list.is_jump_mini.is_jump_mini;
                if (this.is_mini_jump == 1) {
                  this.username = this.list.is_jump_mini.username;
                }
              }
            });
          } else {
            clearInterval(Interval);
          }
        })();
      }, 1000);
    }
  },
  mounted() {
    let mchData = {
      action: "onIframeReady",
      displayStyle: "SHOW_CUSTOM_PAGE",
      height: 740,
    };
    let postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
  },
  methods: {
    iswx() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/Alipay/i) == "alipay") {
        typeBower = "ali";
      }
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        typeBower = "wx";
      }
      return typeBower;
    },
    zhifu_success() {
      let mchData = {
        action: "jumpOut",
        jumpOutUrl: `https://pay-kf-test.hzhhkeji.com/mini_program?order_no=${this.order_no}&username=${this.username}`, //这边还需要加上订单号
      };
      let postData = JSON.stringify(mchData);
      parent.postMessage(postData, "https://payapp.weixin.qq.com");
    },
  },
};
</script>
<style lang="less" scoped>
.pay {
  width: 100vw;
  height: 700px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 0.18rem;
}
.pay_concent {
  width: 100%;
  height: 5.4rem;
  background: #ffffff;
  border-radius: 0px 0px 0.16rem 0.16rem;
}
.pay_success {
  width: 100%;
  height: 2.9rem;
  display: flex;
  justify-content: center;
  align-content: center;
}
.payitem {
  width: 5.9rem;
  //   height:1.76rem;
  border-bottom: solid 1px #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.imgsuccess {
  width: 2.4rem;
  height: 1.8rem;
  margin-top: 0.2rem;
}
.successsfont {
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a4a4a;
  margin-top: 0.2rem;
}
.pay_concent2 {
  width: 100%;
  height: 2.5rem;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
}
.pay_concent3 {
  width: 6rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.pay_concent4 {
  display: flex;
  justify-content: space-between;
}
.pay_concent4 div:first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.pay_concent5 {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.pay_concent6 {
  font-size: 0.3rem;
  font-family: OPPOSans-B, OPPOSans;
  font-weight: 600;
  color: #ff0000;
}
.pay_concent6 span {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff0000;
}
.concent {
  position: relative;
  width: 100%;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;
  // background-color: #f9f9f9;
  overflow: hidden;
  border: none;
}
.concent2 {
  width: 6.9rem;
  height: 2.4rem;
  // background: linear-gradient(135deg, #ff7a00 0%, #ff171b 100%);
  border-radius: 0.16rem;
  overflow: hidden;
  border: none;
  // background-color: #f9f9f9;
  border: none;
}

.concent3 {
  position: relative;
  width: 100%;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.concent4 {
  width: 6.9rem;
  height: 100%;
  background: linear-gradient(90deg, #6dd572 0%, #58b85c 100%);
  border-radius: 0.16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  left: 0.04rem;
  border: none;
  margin-top: 0.6rem;
  // letter-spacing:0.2rem;
}
.span1 {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.concent4 p {
  margin: 0.1rem;
}
.spanabs2 {
  margin-left: -0.6rem;
}
.concent2img {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>